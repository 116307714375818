import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { graphql, useStaticQuery } from 'gatsby';
import React, { MouseEventHandler } from 'react';
import { processImageAsset, unwrapSingleton } from 'helpers/csTypeProcessors';
import { PageTitle } from 'helpers/eventTracking';
import { PetType } from 'helpers/getPetTypesBeingInsured';
import {
  CoverPercentagePlaceholderData,
  coverPercentagePlaceholders,
} from 'helpers/placeholders/coverPercentagePlaceholders';
import { replacePlaceholdersPlainText } from 'helpers/placeholders/replaceCsPlaceholders';
import usePercentageOfClaimsCovered from 'helpers/usePercentageOfClaimsCovered';
import { getVetFeeLimitName } from 'helpers/vetFeeLimitHelper';
import { ProductType } from 'state/formData/quoteOptions';
import { CsAsset, CsIcon } from 'types/contentStack';
import DonutChart from './DonutChart';
import {
  BannerGridItem,
  BorderGridItem,
  ClaimsCoveredContainer,
  GridWithMargins,
  Heading,
  QualityRatingContainer,
  QualityRatingHeading,
  QualityRatingImageWrapper,
  QualityRatingsSection,
  QualityRatingText,
  QualityRatingTextContainer,
  RichTextWithModalAndMargin,
  StyledPaymentPriceBanner,
  WhichBestBuySizedImage,
} from './styles';
import ProductFeaturesTable, { ProductFeature } from '../ProductFeatureTable';

type CoverPanelProps = {
  isAnnualPayment: boolean;
  vetFeeLimit: ProductType;
  paymentOnClick: MouseEventHandler;
  claimsCoveredContainerRef: React.RefObject<HTMLDivElement>;
  petTypesBeingInsured: PetType;
};

type CoverPanelCsProps = {
  csPetQuoteSummaryCoverPanel: {
    heading: string;
    percentage_of_claims_covered: {
      donut_chart_text: string;
      treatment_cost_text: string;
      claims_caveat_text: {
        dog_text: string;
        cat_text: string;
      };
    };
    which_best_buy: {
      heading: string;
      dog_logo: CsAsset | null;
      dog_text: string;
      cat_logo: CsAsset | null;
      cat_text: string;
    };
  };
  csPetQuoteSummaryProductFeatures: {
    product_features: ProductFeature[];
    for_more_benefits_text: string;
    this_cover_includes_text: string;
  };
  csPetAnnualPayment: {
    pay_monthly_promo: {
      body: string;
      heading: string;
      icon: [CsIcon];
    };
  };
};

export const query = graphql`
  query {
    csPetQuoteSummaryCoverPanel {
      heading
      percentage_of_claims_covered {
        donut_chart_text
        treatment_cost_text
        claims_caveat_text {
          dog_text
          cat_text
        }
      }
      which_best_buy {
        heading
        dog_logo {
          ...CsAsset
        }
        dog_text
        cat_logo {
          ...CsAsset
        }
        cat_text
      }
    }
    csPetQuoteSummaryProductFeatures {
      product_features {
        modal {
          modal_id
        }
        feature_text
      }
      for_more_benefits_text
      this_cover_includes_text
    }
    csPetAnnualPayment {
      pay_monthly_promo {
        body
        heading
        icon {
          icon_code
        }
      }
    }
  }
`;

const CoverPanel: React.FC<CoverPanelProps> = ({
  isAnnualPayment,
  vetFeeLimit,
  paymentOnClick,
  claimsCoveredContainerRef,
  petTypesBeingInsured,
}) => {
  const {
    csPetQuoteSummaryCoverPanel: {
      heading,
      percentage_of_claims_covered,
      which_best_buy,
    },
    csPetQuoteSummaryProductFeatures: {
      product_features,
      for_more_benefits_text,
      this_cover_includes_text,
    },
    csPetAnnualPayment: { pay_monthly_promo },
  } = useStaticQuery<CoverPanelCsProps>(query);

  const coverPercentage = usePercentageOfClaimsCovered(
    vetFeeLimit || 0,
    petTypesBeingInsured
  );

  const getPetText = (): string => {
    switch (petTypesBeingInsured) {
      case PetType.CatsOnly:
        return percentage_of_claims_covered.claims_caveat_text.cat_text;
      case PetType.DogsOnly:
        return percentage_of_claims_covered.claims_caveat_text.dog_text;
      default:
        return '';
    }
  };

  const coverPlaceholderData: CoverPercentagePlaceholderData = {
    coveredClaimsPercentage: coverPercentage,
    coverLimit: vetFeeLimit ? getVetFeeLimitName(vetFeeLimit) : '',
    petType: getPetText(),
  };

  const substitutePlaceholders = replacePlaceholdersPlainText(
    coverPercentagePlaceholders,
    coverPlaceholderData
  );

  const whichBestBuyLogo = processImageAsset(
    petTypesBeingInsured === PetType.CatsOnly
      ? which_best_buy.cat_logo
      : which_best_buy.dog_logo
  );

  const whichBestBuyText =
    petTypesBeingInsured === PetType.CatsOnly
      ? which_best_buy.cat_text
      : which_best_buy.dog_text;

  const showWhichBestBuyPanel = [
    ProductType.Vet_Fee_Limit_5000,
    ProductType.Vet_Fee_Limit_7500,
    ProductType.Vet_Fee_Limit_9000,
    ProductType.Vet_Fee_Limit_12000,
  ].includes(vetFeeLimit);

  const showQualityRatingsSection = showWhichBestBuyPanel;

  const payMonthlyPromoIcon = unwrapSingleton(pay_monthly_promo.icon)?.icon_code;
  const priceBannerAnnualInfo = {
    heading: pay_monthly_promo.heading,
    body: pay_monthly_promo.body,
    headerIcon: payMonthlyPromoIcon,
  };

  return (
    <GridWithMargins alignLeft data-cy="coverPanel">
      <BannerGridItem desktop={8} tabletLandscape={8} tabletPortrait={6}>
        <Grid alignLeft>
          <GridItem>
            <Heading>{substitutePlaceholders(heading)}</Heading>
          </GridItem>
          <BorderGridItem>
            <ClaimsCoveredContainer ref={claimsCoveredContainerRef}>
              <StyledPaymentPriceBanner
                isAnnualPayment={isAnnualPayment}
                switchPaymentType={paymentOnClick}
                selectedPrice={vetFeeLimit}
                annualPaymentInfo={priceBannerAnnualInfo}
              />
              <DonutChart
                coveredClaimsPercentage={coverPercentage}
                chartText={substitutePlaceholders(
                  percentage_of_claims_covered.donut_chart_text
                )}
              />
            </ClaimsCoveredContainer>

            <RichTextWithModalAndMargin
              html={substitutePlaceholders(
                percentage_of_claims_covered.treatment_cost_text
              )}
              pageTitle={PageTitle.QuoteSummary}
            />
            {showQualityRatingsSection && (
              <QualityRatingsSection>
                {showWhichBestBuyPanel && (
                  <QualityRatingContainer data-cy="whichBestBuy">
                    {whichBestBuyLogo && (
                      <QualityRatingImageWrapper>
                        <WhichBestBuySizedImage image={whichBestBuyLogo} />
                      </QualityRatingImageWrapper>
                    )}
                    <QualityRatingTextContainer>
                      <QualityRatingHeading>
                        {which_best_buy.heading}
                      </QualityRatingHeading>
                      <QualityRatingText>{whichBestBuyText}</QualityRatingText>
                    </QualityRatingTextContainer>
                  </QualityRatingContainer>
                )}
              </QualityRatingsSection>
            )}
            <ProductFeaturesTable
              features={product_features}
              forMoreBenefitsText={for_more_benefits_text}
              thisCoverIncludesText={this_cover_includes_text}
            />
          </BorderGridItem>
        </Grid>
      </BannerGridItem>
    </GridWithMargins>
  );
};

export default CoverPanel;
